import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { Button } from '@material-ui/core';
import { CircleWavyQuestion } from 'phosphor-react';
import { withStyles } from '@material-ui/core/styles';

import track from '../../../lib/track';
import combineStyles from '../../../styles/combineStyles';
import { buttonStyles } from '../../../styles/base';
import styles from '../../../styles/sass/components/HelpDialog.module.scss';

export interface Props {
  classes: any;
  closeDialog: Function;
}

export const HelpDialogContents: React.FC<Props> = ({
  classes,
  closeDialog,
}) => {
  const [tracked, setTracked] = useState<any>({});

  useEffect(() => {
    if (!tracked) {
      track.modalView('Help Dialog');
      setTracked({ tracked: true });
    }
  }, []);

  const openChat = () => {
    closeDialog();
    const win: any = window.open('/contact', '_blank');
    win.focus();
  };

  return (
    <div className={styles.helpDialogInner}>
      <CircleWavyQuestion size={80} color="#443461" weight="regular" />
      <div className={styles.helpDialogHeader}>
        <div className={styles.helpDialogHeaderText}>
          <h3>Need Help?</h3>
          <span>Our licensed agents are available to talk.</span>
        </div>
      </div>
      <Button
        variant="contained"
        style={{ marginTop: 56 }}
        className={classes.buttonPurple}
        size="large"
        onClick={openChat}
      >
        <span className={styles.chatButtonText}>Email Us</span>
      </Button>
      <span className={styles.helpDialogText}>
        You can also check out our
        <Link href="/buying-with-torii?focus=faq">
          <a aria-label="FAQ" className={styles.helpLink} target="_blank">
            FAQ
          </a>
        </Link>
      </span>
    </div>
  );
};

const combinedStyles = combineStyles(buttonStyles, styles);

export default withStyles(combinedStyles)(HelpDialogContents);
