import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import ResponsiveDialog from '../../utility/ResponsiveDialog';
import HelpDialogContents from './HelpDialogContents';
import { isMobile } from '../../../lib/getDeviceSize';
import * as types from '../../../actions/actionTypes';

import styles from '../../../styles/sass/components/HelpDialog.module.scss';

export interface Props {
  dispatch: any;
  open: boolean;
}

export const HelpDialog: React.FC<Props> = props => {
  const handleClose = () => {
    props.dispatch({ type: types.CloseHelpDialog });
  };

  return (
    <ResponsiveDialog
      mediumLargePaper={isMobile() ? false : true}
      mediumPaper={isMobile() ? true : false}
      onClose={handleClose}
      open={props.open}
      className={styles.helpDialog}
      closeIconStyles={{
        width: '32',
        height: '32',
      }}
      closeButtonColor="rgba(85, 85, 85, 1)"
      buttonClassName={styles.helpDialogCloseButton}
      backdrop={'transparent'}
    >
      <HelpDialogContents closeDialog={handleClose} />
    </ResponsiveDialog>
  );
};

const mapStateToProps = (state: any) => ({
  open: state.dialogs.helpDialogOpen,
});

export default connect(mapStateToProps)(HelpDialog);
